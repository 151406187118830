import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';

import typography from '../../../theme/typography';

const SensorLimitField: React.FC<{
  color: string;
  dataTestId?: string;
  label: string;
  onChange: (value: string) => void;
  unit?: string;
  value: number | string;
}> = ({ color, dataTestId, label, onChange, unit, value }) => (
  <Grid alignItems="center" container gap="5px" item md={3} sm={6} xs={12}>
    <Typography variant="text-xs">{label}</Typography>
    <TextField
      InputProps={{
        style: {
          marginTop: 0,
        },
      }}
      inputProps={{
        'data-testid': dataTestId,
        style: {
          borderRadius: '8px',
          color,
          ...typography['text-xs-bold'],
          padding: '0 0 0 20px',
        },
      }}
      onChange={(e) => onChange(e.target.value)}
      style={{ marginRight: '4px', width: '140px' }}
      type="number"
      value={value !== null && value !== undefined ? value : ''}
    />
    <Typography variant="text-xs-bold">{unit}</Typography>
  </Grid>
);

export default SensorLimitField;
